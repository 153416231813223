<template>
    <main class="layout__main--narrow layout__main--no-top-padding">
        <BaseHeader
            v-if="pending || headerImage"
            :placeholder="pending"
            :image="headerImage"
        />

        <article
            class="bleed"
        >
            <transition name="fade">
                <BaseSection
                    v-if="!pending && page"
                    class="container-narrow"
                >
                    <template #title>
                        <h1>{{ page.title }}</h1>
                    </template>

                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        v-if="page.introText"
                        class="lead"
                        data-allow-anchor
                        v-html="page.introText"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                </BaseSection>

                <BaseSection
                    v-else-if="pending"
                    class="container-narrow"
                >
                    <template #title>
                        <h1>
                            <ContentPlaceholdersHeading :lines="2" />
                        </h1>
                    </template>

                    <p class="lead">
                        <content-placeholders-text :lines="5" />
                    </p>
                </BaseSection>
            </transition>

            <FlexibleSections
                :sections="page ? page.sections : []"
                :placeholder="pending ? 'content' : undefined"
            />
        </article>

        <BaseSection
            v-if="tagIds && tagIds.length && !noRelatedNews"
            class="section--wide section--extra-spacing"
        >
            <template #title>
                <h2>Suggested for you</h2>
            </template>

            <template #action>
                <nuxt-link
                    to="/news"
                    aria-label="See all news"
                >
                    See all <BaseIcon icon="chevron-right" />
                </nuxt-link>
            </template>

            <template #default>
                <RelatedNews
                    v-if="tagIds"
                    :tags="tagIds"
                    @empty="newsIsEmpty"
                />
            </template>
        </BaseSection>
    </main>
</template>

<script setup lang="ts">
import PageQuery from '~/graphql/queries/Page.graphql';
const { activeSite } = useMultisite();

const noRelatedNews = ref(false);

const { variables } = useCraftVariables();
const response = await useLazyAsyncQuery({
    query: PageQuery,
    variables
});

const {
    craftEntry: page,
    pending,
    headerImage
} = usePageQuery(
    response,
    { entryKey: 'page', activeSite },
);


const tagIds = computed(() => {
    if (toValue(page)?.tags) {
        return toValue(page)?.tags?.map(({ id }: {
            id: number | string;
        }) => id);
    }
    return null;
});

const newsIsEmpty = () => {
    noRelatedNews.value = true;
};
</script>
